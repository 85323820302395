import { ClientLogo, LogoContainer, StyledSectionWrapper } from "./ClientListSection.styles"

import React from 'react';

const ClientListSection = (props) => {

  return(
    <StyledSectionWrapper>
      <LogoContainer>
        <ClientLogo
            src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-Appzen.png"
            alt="Appzen logo."
        />
        <ClientLogo
            src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-HP.png"
            alt="HP logo."
        />
        <ClientLogo
            src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-Asurion.png"
            alt="Asurion logo."
        />
        <ClientLogo
            src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-IBM.png"
            alt="IBM logo."
        />
        <ClientLogo
            src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-GSDM.png"
            alt="GSD&M logo."
        />
        <ClientLogo
            src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-KPMG.png"
            alt="KPMG logo."
        />
      </LogoContainer>
    </StyledSectionWrapper>
  )
};

export default ClientListSection;
