import ClientListSection from "../components/page-specific/Our-Clients/ClientListSection"
import CtaSection from "../components/sections/CtaSection"
import IllustrationHeader from "../components/global/IllustrationHeader"
import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection"
import React from "react"
import styled from 'styled-components';

const Partners = ({ data, location }) => {

    const partners = [
        {
            quote: "Having a corporate account has made it easier to make sure that all employees are taken care of.",
            author: "Giana R",
            position: "Workplace & Employee Experience Manager at GoFundMe",
            imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-GoFundMe.png",
            alt: "GoFundMe logo"
        },
        {
            quote: "Using Grubhub corporate features has been been instrumental in providing a bit of happiness to our employees during these chaotic times.",
            author: "Meghan M",
            position: "Office Administrator at Compass",
            imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-Compass.png",
            alt: "Compass logo"
        },
        {
            quote: "Grubhub has been our go-to corporate meal solution since we started 13 years ago. Customer service is also #1!",
            author: "Nichole S",
            position: "Managing Director at Telsey Advisory Group",
            imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-TAG.png",
            alt: "TAG logo"
        }
    ]

    return (
        <Layout
            location={location}
            title={"Our Clients | Grubhub Corporate"}
            description={"Check out what some of the companies loving their Grubhub Corporate Account have to say about it."}
            utag_data={{
                pageGroup: "grubhub - lead generation",
                subGroup: "partner with us",
                pageName: "partner stories",
                brand: "grubhub"
            }}
        >
            <IllustrationHeader 
                headline="Our clients" 
                subheadline="Just a few of the companies loving their Grubhub Corporate Account" 
                ctaLink="/get-started/"
                background='radial-gradient(circle, #44C3C0 0%, #44C3C0 0%, #189096 100%)'
                textColor="white"
                leftSideIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1628277587/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/7-Our%20Clients/DESKTOP-OurClients-LEFT_IMAGE.png"
                altLeftSide="Illustration of white food delivery bag with a red heart floating above as well as a bowl of ramen noodles with chopsticks."
                rightSideIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933616/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Header%20Images/Illustrated%20Headers/7-Our%20Clients/DESKTOP-OurClients-RIGHT_IMAGE-Citrus.png"
                altRightSide="Illustrations of two brown food containers stacked on top of each other labeled “Ben” and “Sarah.”"
                mobileIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933619/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Header%20Images/Illustrated%20Headers/7-Our%20Clients/MOBILE-OurClients-HeaderImage-Citrus.png"
                altMobile="Illustrations of two brown food containers stacked on top of each other labeled “Ben” and “Sarah.”"
            />
            <ClientListSection />
            {partners.map((partner, i) => (
                <React.Fragment key={i}>
                    <div className="container"><QuoteDivider /></div>
                    <QuoteSection
                        quote={partner.quote}
                        author={partner.author}
                        position={partner.position}
                        imageSrc={partner.imageSrc}
                        // If even numer, align right, else left
                        imageAlignment={ i % 2 === 0 ? "right" : "left"}
                        layout="horizontal"
                     />
                </React.Fragment>
            ))}
            <CtaSection
                headline="See what we can do for you"
                to="/get-started/"  
                backgroundColor="#F9F9F9"
            />
        </Layout>
    )
}

export default Partners

const QuoteDivider = styled.hr`
    border-color: var(--color-primary-dark);
    opacity: 20%;
    border-width: 2px;
`;
