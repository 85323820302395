import SectionWrapper from "../../../sections/SectionWrapper"
import { queries } from "../../../../breakpoints"
import styled from 'styled-components';

export const ClientLogo = styled.img`
    width:100%;
    max-width: 150px;
    @media(${queries.min_break_lg}){
        max-width:250px;
    }
`;

export const LogoContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-items: center;
    
    @media(${queries.max_break_md}){
        row-gap:20px;
        grid-template-columns: repeat(3, 1fr);
    }
    @media(${queries.max_break_sm}){
        row-gap:20px;
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const StyledSectionWrapper = styled(SectionWrapper)`
    // remove the max-width on the container for sizes under break_lg so the slider extends to the edge and off the screen
    .container{
        max-width: unset;
        @media(${queries.min_break_lg}){
            max-width:1600px;
        }
    }
    
`;
